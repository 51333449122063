import Layout from "@/components/Layout";
import React, { useState } from "react";
import { graphql } from "gatsby";
import { KeystoneImage } from "@/components/gatsby-image-keystone";
import { setGlobalState, useGlobalState } from "@/state";
import { CustomRenderer } from "@/utils/CustomRenderer";

const Products = ({ data }: any) => {
  const NavigationStyling = `w-full w-[200px] px-[10px] border-b-[1px] border-orange py-[10px] md:py-[20px] text-center hover:bg-orange md:border-0 hover:text-white`;
  const ActiveNavigationStyling = `w-full w-[200px] px-[10px] border-b-[1px] border-orange py-[10px] md:py-[20px] text-center bg-orange text-white md:border-0`;

  const products = data.cms.products;

  var defaultTab = products[0];

  const tab = useGlobalState("products")[0].toString();

  products.map((e: any) => {
    e.title == tab ? (defaultTab = e) : <></>;
  });

  const subMenu = products.map((e: any) => {
    return (
      <button
        key={products.indexOf(e)}
        onClick={() => {
          setGlobalState("products", e.title);
        }}
        className={
          defaultTab.title !== e.title
            ? NavigationStyling
            : ActiveNavigationStyling
        }
      >
        <p className="text-[16px]">{e.title}</p>
      </button>
    );
  });

  return (
    <Layout title="Products">
      <div key={defaultTab.id} className="lg:container px-[20px] xl:px-0">
        <div className="text-center flex flex-col gap-[2rem] mb-[4rem]">
          <h1>Products</h1>
          <p className="lg:text-[22px] md:w-[90%] mx-auto">
            Below are the list of products developed by Steppe Link LLC,
            available to purchase with customization.
          </p>
        </div>
        {/* Contents */}
        <div className="flex mb-[4rem] flex-col mx-auto border-b-[2px] border-orange md:flex-row max-w-max">
          {subMenu}
        </div>

        {/* Content */}
        <div>
          <div className="lg:container dark:bg-gray bg-white rounded-xl flex flex-col justify-center">
            <div className="rounded-xl overflow-hidden min-w-full">
              {defaultTab.featureImage && (
                <KeystoneImage
                  baseUrl="steppelink"
                  image={{
                    alt: defaultTab.featureImage.id,
                    width: 1100,
                    height: 500,
                    key: `${defaultTab.featureImage.id}.${defaultTab.featureImage.extension}`,
                  }}
                  layout="fullWidth"
                  alt={defaultTab.featureImage.id}
                />
              )}
            </div>

            <div className="flex px-[20px] xl:px-0 flex-col justify-center my-[2rem]">
              <div className="mx-auto max-w-[1000px] mb-[2rem]">
                <div className="text-center mb-[2rem]">
                  <CustomRenderer document={defaultTab.content.document} />
                </div>
                <CustomRenderer document={defaultTab.contentFull.document} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Products;

export const query = graphql`
  query ProductPageQuery {
    cms {
      products(
        where: { status: { equals: "published" } }
        orderBy: { order: asc }
      ) {
        title
        status
        content {
          document(hydrateRelationships: true)
        }
        contentFull {
          document(hydrateRelationships: true)
        }
        featureImage {
          extension
          height
          id
          width
        }
      }
    }
  }
`;
